import { InMemoryCache, FieldPolicy } from '@apollo/client';

import { PractitionerType, CareTeamType } from './types';

const typedCachePolicy = <T extends { uid: string }>(): FieldPolicy => (
  {
    merge(existing: T, incoming: T): T {
      if (existing?.uid && incoming?.uid && incoming.uid === existing.uid) {
        return {
          ...existing,
          ...incoming,
        };
      }
      return incoming;
    },
  }
);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        practitioner: typedCachePolicy<PractitionerType>(),
        careteam: typedCachePolicy<CareTeamType>(),
      },
    },
  },
});
